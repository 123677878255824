export enum BoardSpaceType {
  None,
  Cost,
  CoinsX,
  TreasureMap,
  FirstXThenY,
  EndOfGameCoinIfSame,
  EndOfGameCoinIfAllDifferent,
  CoinPerDifferent,
  CoinPerFullColumn,
  Replay,
  Flip,
}